var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns is-multiline"},[_vm._m(0),_c('div',{staticClass:"column text-right"},[_c('b-button',{attrs:{"type":"is-primary","outlined":""},on:{"click":_vm.openModalAddRequisito}},[_vm._v("Agrega requisito")])],1),_c('div',{staticClass:"column is-12"},_vm._l((_vm.getTiposRequisito),function(tipo){return _c('b-tag',{key:tipo.id,staticClass:"cursor-pointer tag-clickable mr-3",class:{ pasivo: _vm.pasivo, 'is-active': tipo.id == _vm.activeTag },nativeOn:{"click":function($event){return _vm.setActiveTag(tipo.id)}}},[_vm._v(" "+_vm._s(tipo.nombre)+" ("+_vm._s(_vm.totalPorTipo(tipo.id))+") ")])}),1),(_vm.requisitosManual.length > 0)?_c('div',{staticClass:"column"},[_c('b-checkbox',{attrs:{"size":"is-medium"},nativeOn:{"change":function($event){return _vm.selectAll($event)}},model:{value:(_vm.isSelectAll),callback:function ($$v) {_vm.isSelectAll=$$v},expression:"isSelectAll"}},[_vm._v(" "+_vm._s(!_vm.isSelectAll ? "Seleccionar todo" : "Deseleccionar todo")+" ")])],1):_vm._e(),_c('div',{staticClass:"column is-12 pb-0"},_vm._l((_vm.requisitosManual),function(requisito){return _c('div',{key:requisito.nombre,staticClass:"card mb-3 p-3 has-border-radius flex",class:{
        'has-background-primary-16': requisito.checked,
        'has-background-white-ter': !requisito.checked
      }},[_c('div',{staticClass:"flex items-center w-4/5"},[_c('b-checkbox',{attrs:{"size":"is-medium"},model:{value:(requisito.checked),callback:function ($$v) {_vm.$set(requisito, "checked", $$v)},expression:"requisito.checked"}}),_c('p',{staticClass:"font-medium ml-8"},[_vm._v(" "+_vm._s(requisito.nombre)+" ")])],1),_c('div',{staticClass:"text-right w-1/5"},[_c('b-button',{staticClass:"has-text-primary",attrs:{"type":"is-text"},on:{"click":function($event){return _vm.seeDetail(requisito)}}},[_vm._v(" Ver detalles "),_c('fa-icon',{attrs:{"icon":"chevron-right"}})],1)],1)])}),0),(_vm.getRequisitosProducto && _vm.getRequisitosProducto.id)?_c('div',{staticClass:"column is-12 pt-0"},_vm._l((_vm.requisitos),function(requisito){return _c('div',{key:requisito.id,staticClass:"mb-3 p-3 has-border-radius flex shadow-none",class:{
        'has-background-primary-16': _vm.form.requisitosSelected.includes(
          requisito.id
        ),
        'has-background-white-ter': !_vm.form.requisitosSelected.includes(
          requisito.id
        )
      }},[_c('div',{staticClass:"flex items-center w-4/5"},[_c('b-checkbox',{attrs:{"size":"is-medium","native-value":requisito.id},model:{value:(_vm.form.requisitosSelected),callback:function ($$v) {_vm.$set(_vm.form, "requisitosSelected", $$v)},expression:"form.requisitosSelected"}}),_c('p',{staticClass:"font-medium ml-8"},[_vm._v(" "+_vm._s(requisito.nombre)+" ")])],1),_c('div',{staticClass:"text-right w-1/5"},[_c('b-button',{staticClass:"has-text-primary",attrs:{"type":"is-text"},on:{"click":function($event){return _vm.seeDetail(requisito)}}},[_vm._v(" Ver detalles "),_c('fa-icon',{attrs:{"icon":"chevron-right"}})],1)],1)])}),0):_vm._e(),(
      !_vm.getRequisitosProducto.id && _vm.form.requisitosManual.length == 0
    )?_c('div',{staticClass:"w-full"},[_c('empty-state',{attrs:{"type":"svg","vertical":true,"imagen":"/images/requisito_placeholder.svg","imgclass":"mb-4","titulo":"No has añadido requisitos","titulo-class":"has-text-grey-light text-xl","subtitulo":"Empieza a añadir requisitos","subtitulo-class":"has-text-grey-light"}})],1):_vm._e(),_c('portal',{attrs:{"to":"modalContainer"}},[(_vm.isAddingRequisito)?_c('b-modal',{attrs:{"active":_vm.isAddingRequisito,"has-modal-card":"","trap-focus":"","width":"300","aria-role":"dialog","aria-modal":""},on:{"update:active":function($event){_vm.isAddingRequisito=$event}}},[_c('modal-nuevo-requisito',{staticClass:"modal-has-overflow",attrs:{"requisito":_vm.requisitoView},on:{"added":_vm.addRequisito}})],1):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-8"},[_c('p',{staticClass:"font-medium text-lg"},[_vm._v("Requisitos agregados")]),_c('p',[_vm._v("Quita o agrega requisitos de la lista.")])])}]

export { render, staticRenderFns }